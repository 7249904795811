import React, { useState } from 'react';
import './App.css';

function App() {
  const [email, setEmail] = useState('');
  const [subscribed, setSubscribed] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://hooks.zapier.com/hooks/catch/19940121/2tbtcu1/', {
        method: 'POST',
        mode: 'no-cors', // Add this line
        body: JSON.stringify({ 
          email: email,
          date: new Date().toISOString()
        }),
      });

      // Since we're using 'no-cors', we can't check response.ok
      // We'll assume it worked if we get here without an error
      setSubscribed(true);
      setEmail('');
      setError('');
    } catch (err) {
      setError('An error occurred. Please try again.');
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Something Exciting is Coming Soon!</h1>
        <p>We're working hard to bring you something amazing. Stay tuned!</p>
        {!subscribed ? (
          <form onSubmit={handleSubmit} className="subscribe-form">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              required
            />
            <button type="submit">Subscribe</button>
          </form>
        ) : (
          <p className="success-message">Thanks for subscribing!</p>
        )}
        {error && <p className="error-message">{error}</p>}
      </header>
    </div>
  );
}

export default App;